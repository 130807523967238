import packageDetails from '../../../package.json';

type AppConfig = {
  appVersion: string;
  sentryDsn: string;
  bucketName: string;
  cloudfrontBaseUrl: string; // To download file from S3
};

const appVersion = packageDetails.version;

let appConfig: AppConfig;
if (
  process.env.REACT_APP_ENV === 'production' ||
  process.env.REACT_APP_ENV === 'stagingWithProd'
) {
  appConfig = {
    appVersion,
    cloudfrontBaseUrl: "https://dkgltj9zc4rm2.cloudfront.net",
    sentryDsn: 'https://56fbe187ad824ea5bf882edbe693887b@o110511.ingest.sentry.io/4504735131369472',
    bucketName: 'flow-builder',
  };
} else {
  appConfig = {
    appVersion,
    cloudfrontBaseUrl: "https://dkgltj9zc4rm2.cloudfront.net",
    sentryDsn: '',
    bucketName: 'flow-builder-dev',
  };
}

export default appConfig;
