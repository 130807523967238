import { css, Global } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './modules/app/components/App';
import resetStyles from './shared/styles/reset';
import theme from './theme';
import * as Sentry from '@sentry/react';
import appConfig from './shared/config/app';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: appConfig.sentryDsn,
  });
}

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Global
          styles={css`
            ${resetStyles}

            html,
          body,
          #root {
              margin: 0px;
              padding: 0px;
              height: 100%;
              width: 100%;
            }

            * {
              -webkit-tap-highlight-color: transparent;
            }

            *,
            :after,
            :before {
              box-sizing: border-box;
            }
          `}
        />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
