import { BotListData } from '../../modules/builder/typings';
import {
  AnalyticsEventNames,
  AnalyticsEventOptions,
} from '../../shared/typings/analytics';
import BasePlatform from './base';

enum WebMessageTypes {
  LOGIN = 'LOGIN',
  READ_ORIGIN = 'READ_ORIGIN',
}

type WebLoginResult = {
  type: WebMessageTypes.LOGIN;
  data: {
    token: string;
  };
};
type WebOriginResults = {
  type: WebMessageTypes.READ_ORIGIN;
  data: {
    origin: string;
  };
};

type WebResult = WebLoginResult | WebOriginResults;

type WebLoginRequestCache = {
  login?: {
    cachedPromiseResolve: (value: string | PromiseLike<string>) => void;
  };
};

type WebRequestCache = WebLoginRequestCache;

export default class Web extends BasePlatform {
  cachedPromise: WebRequestCache;

  constructor() {
    super();
    this.cachedPromise = {};
  }

  isCurrentPlatform(): boolean {
    const currentPlatform = typeof window !== 'undefined';
    if (currentPlatform) {
      this.listenForMessageFromWeb();
    }
    return currentPlatform;
  }

  private listenForMessageFromWeb() {
    window.onmessage = ({ data }: { data: any }) => {
      if (typeof data !== 'string') {
        return;
      }

      try {
        this.handleMessageFromWeb(JSON.parse(data) as unknown as WebResult);
      } catch (error) {
        // Maybe data was not valid JSON, or event postMessage was called from some 3rd party script
      }
    };
  }

  private handleMessageFromWeb({ type, data }: WebResult) {
    switch (type) {
      case WebMessageTypes.LOGIN:
        this.cachedPromise.login?.cachedPromiseResolve(data.token);
        delete this.cachedPromise.login;
        break;
      case WebMessageTypes.READ_ORIGIN: {
          localStorage?.setItem('parent-origin', data.origin);
          break;
        }
      default:
    }
  }

  async getToken(): Promise<string> {
    return new Promise((resolve) => {
      this.cachedPromise.login = {
        cachedPromiseResolve: resolve,
      };

      window.parent.postMessage({ type: 'ASK_TOKEN' }, '*', []);
    });
  }

  getOrigin() {
    window?.parent?.postMessage?.({ type: 'ASK_ORIGIN' }, '*', []);
  }

  getVersion(): number {
    return 1;
  }

  goBack(): void {
    window.parent.postMessage({ type: 'GO_BACK' }, '*', []);
  }

  logout(): void {
    // throw new Error('Not implemented');
  }

  finishActivity(): void {
    window.parent.postMessage({ type: 'CLOSE_IFRAME' }, '*', []);
  }

  redirect(url: string): void {
    window.parent.postMessage({ type: 'REDIRECT', data: { url } }, '*', []);
  }

  hideModalHeader(title: string): void {
    window.parent.postMessage({ type: 'HIDE_MODAL_HEADER', title }, '*', []);
  }

  showModalHeader(): void {
    window.parent.postMessage({ type: 'SHOW_MODAL_HEADER' }, '*', []);
  }

  addNewBot(data: BotListData): void {
    window.parent.postMessage({ type: 'ADD_BOT', data }, '*', []);
  }

  editBot(data: Partial<BotListData>): void {
    window.parent.postMessage({ type: 'EDIT_BOT', data }, '*', []);
  }

  trackEvent(
    eventName: AnalyticsEventNames,
    properties?: AnalyticsEventOptions | undefined
  ): void {
    window.parent.postMessage(
      { type: 'TRACK_EVENT', data: { eventName, properties } },
      '*',
      []
    );
  }
}
