import { lazy, Suspense } from 'react';
import { Navigate, Route } from 'react-router-dom';
import Loader from '../../../../../shared/components/atoms/loader';
import { ROUTES } from '../../../../../shared/constants/routes';

const BuilderLazyComponent = lazy(
  () => import('../../../../builder/components/')
);

const BotListLazyComponent = lazy(() => import('../../../../bot-list'));

export default function getRoutesV1() {
  return [
    <Route
      key={`${ROUTES.BOTS}/*`}
      path={`${ROUTES.BOTS}/*`}
      element={
        <Suspense fallback={<Loader />}>
          <BuilderLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={`${ROUTES.BOTS}`}
      path={`${ROUTES.BOTS}`}
      element={
        <Suspense fallback={<Loader />}>
          <BotListLazyComponent />
        </Suspense>
      }
    />,

    <Route key="*" path="*" element={<Navigate to={'/bots'} replace />} />,
  ];
}
