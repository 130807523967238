import { Route, Routes } from 'react-router-dom';
import getRoutesV1 from './v1';
import Loader from '../../../../shared/components/atoms/loader';
import { Suspense, lazy } from 'react';

export function AppProtectedRoutes() {
  return (
    <Routes>
      <>{getRoutesV1()}</>
      <Route path="*" element={<div>wrong route</div>} />
    </Routes>
  );
}

const ZohoLogin = lazy(
  () => import('../../../../modules/builder/components/login-success/zoho')
);

export function AppUnprotectedRoutes() {
  return (
    <Routes>
      <Route
        path="/bots/zoho-login"
        element={
          <Suspense>
            <ZohoLogin />
          </Suspense>
        }
      />
      <Route path="*" element={<Loader />} />
    </Routes>
  );
}
