import { BotListData } from '../../modules/builder/typings';
import {
  AnalyticsEventNames,
  AnalyticsEventOptions,
} from '../../shared/typings/analytics';

export default abstract class BasePlatform {
  abstract isCurrentPlatform(): boolean;
  abstract getToken(): Promise<string>;
  abstract goBack(): void;
  abstract getOrigin(): void;
  abstract finishActivity(): void;
  abstract logout(): void;
  abstract redirect(url: string): void;
  abstract hideModalHeader(title: string): void;
  abstract showModalHeader(): void;
  abstract addNewBot(data: BotListData): void;
  abstract editBot(data: Partial<BotListData>): void;
  abstract trackEvent(
    eventName: AnalyticsEventNames,
    properties?: AnalyticsEventOptions
  ): void;
}
