import BasePlatform from './base';
import Web from './web';

let platform!: BasePlatform;

const webPlatform = new Web();

if (webPlatform.isCurrentPlatform()) {
  webPlatform.isCurrentPlatform();
  platform = webPlatform;
}

// TODO add platform unsupported code in index.js. Convert direct execution to a function execution
export default platform;
