/**@jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from '@emotion/react';
import {
  CircularProgress,
  CircularProgressProps,
  TypographyProps,
} from '@mui/material';
import { FC } from 'react';
import QSTypography from '../typography';

interface LoaderProps {
  secondary?: React.ReactNode;
  primary?: React.ReactNode;
  size?: string | number;
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    css?: Interpolation<Theme>;
  };
  loaderProps?: CircularProgressProps;
  textProps?: TypographyProps;
}

const Loader: FC<LoaderProps> = ({
  size,
  loaderProps,
  textProps,
  containerProps,
  primary,
  secondary = 'Loading...',
}) => {
  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
      {...containerProps}
    >
      {primary ? primary : <CircularProgress size={size} {...loaderProps} />}

      {secondary && typeof secondary === 'string' ? (
        <QSTypography
          sx={{
            marginTop: '12px',
            fontSize: '16px',
            fontWeight: '400px',
          }}
          {...textProps}
        >
          {secondary}
        </QSTypography>
      ) : (
        secondary
      )}
    </div>
  );
};

export default Loader;
