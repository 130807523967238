import platform from '../platform';

let token = '';

export async function getFirebaseToken() {
  return token;
}

export async function getFirebaseTokenFromPlatform() {
  token = await platform.getToken();
  return;
}
