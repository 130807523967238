import React, { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface QSTypographyProps extends TypographyProps {
  children?: React.ReactNode;
}

const QSTypography: FC<QSTypographyProps> = ({ sx, children, ...props }) => {
  return (
    <Typography
      sx={{
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default QSTypography;
